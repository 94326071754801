import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBlock title="Patricia Puia" subtitle="Secondary Principal" mdxType="AuthorBlock">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "686px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/cf2d7242ef28b56f86c647b773eab8df/9050f/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEBQP/xAAZAQACAwEAAAAAAAAAAAAAAAABAwACBAX/2gAMAwEAAhADEAAAAcjsWmZQw5Nlea2fpoKWH//EABsQAAEFAQEAAAAAAAAAAAAAAAIAAQMREhAx/9oACAEBAAEFAha3KOhUVanrChcRIzjyh4/v/8QAGxEAAgEFAAAAAAAAAAAAAAAAAAECEBIiMXH/2gAIAQMBAT8BjHGlq2Ph/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAIRARL/2gAIAQIBAT8BZt6ISlP/xAAcEAABBAMBAAAAAAAAAAAAAAABABASIREgQSL/2gAIAQEABj8CtS4wyuW3oUjGydf/xAAeEAADAAEEAwAAAAAAAAAAAAAAAREhEDFRYYGh0f/aAAgBAQABPyFGREN7yEPIBtps+GlRVvQp8MHKNgoqY//aAAwDAQACAAMAAAAQ9/38/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARMf/aAAgBAwEBPxAXTbD5xA3i/8QAFxEBAQEBAAAAAAAAAAAAAAAAAREAEP/aAAgBAgEBPxAUHCFXVv/EABwQAQEBAQACAwAAAAAAAAAAAAERACExUUFhkf/aAAgBAQABPxAYewQuWVeBkfzOReU7kxYQSB6duTuLA+ZKr2YmRxHOfbH0ZorbvJyxTf/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/cf2d7242ef28b56f86c647b773eab8df/9050f/01.jpg",
              "srcSet": ["/static/cf2d7242ef28b56f86c647b773eab8df/f93b5/01.jpg 300w", "/static/cf2d7242ef28b56f86c647b773eab8df/b4294/01.jpg 600w", "/static/cf2d7242ef28b56f86c647b773eab8df/9050f/01.jpg 686w"],
              "sizes": "(max-width: 686px) 100vw, 686px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <blockquote>
      <p parentName="blockquote">{`As always, we have had a school year in which our students have learned and expanded their horizons. This year, of course, that has included some fairly dramatic twists and turns.`}</p>
    </blockquote>
    <p>{`In athletics, we continue our long relationship with both the local international school league (KSSL) and with CEESA. In addition to sending teams to Baku, Riga,Vilnius and Istanbul, we were pleased to host the middle and high school CEESA swimming competition and cross-country tournament.`}</p>
    <p>{`Students also represented PSI abroad at the Model United Nations (MUN) conference and the MathCounts competition. For the first time ever, PSI also hosted the high school International Schools Theatre Association (ISTA) workshops, an exciting opportunity to showcase our beautiful city, our great auditorium, and - of course! - our talented students.`}</p>
    <p>{`As we moved into March, we faced the global challenge of the coronavirus pandemic and the closure of our campus. Whilst it has brought us many challenges and some disappointment, it has also been a remarkable testament to the dedication of our teachers and support staff, the commitment of our students, and the ongoing power of collaboration with our parents. We worked closely with students who found the new learning environment challenging, but we also celebrated many successes, as students found new ways to learn and grow.`}</p>
    <p>{`Perhaps the hardest hit group was our Grade 12s. Their Mock Exams were interrupted midway through. The IB cancelled the May exams and placed a stronger emphasis on students’ Internal Assessments. The usual milestones of their year - prom and graduation - could not be held in ‘real time’. Universities around the world are scrambling to redefine their admissions criteria.`}</p>
    <p>{`And yet our students persisted. An enthusiastic group of volunteers worked with school leadership to develop an alternative graduation ceremony, which will undoubtedly be memorable for us all.`}</p>
    <p>{`And we continued to maintain our tradition of sending students to universities that are a good fit for their needs, their abilities, and their dreams for the future. We applaud the students who have received conditional acceptances to UK and European universities like Cambridge, Warwick, Greenwich, St Andrews, Erasmus and Turku. Many students have also received unconditional acceptance to North American schools including the New School, University of Michigan, University of Washington, University of British Columbia, and Virginia Commonwealth University. The University of Rotterdam in Europe also offered three unconditional acceptances. In addition, we have some students taking a gap year to pursue jobs of interest and travel opportunities.  We look forward to seeing where all of our graduates ‘land’ next year and wish them well as they leave PSI for the wider world.`}</p>
    <p>{`Finally, as this is my last year at PSI, I wish to thank all members of the community - students, staff and parents - for their ongoing support of our school and for always making this a `}<strong parentName="p">{`‘Place Where We Belong.’`}</strong>{` It has been one of the great joys of my life to have been part of PSI.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      